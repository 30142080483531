@use "@/sass/abstracts/common";
@use "@/sass/variables/colors.module";

@mixin provider-login-button {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 400px;
  height: 44px;
  padding: 10px 16px;
  white-space: nowrap;
  border-radius: 8px;
  cursor: pointer;
  font-synthesis: none;
  font-feature-settings: kern;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &:not(.disableMargin) {
    margin-bottom: 16px;
  }

  svg {
    position: relative;
  }

  &.isDisabled {
    cursor: auto;
    opacity: 0.5;
  }

  @include common.hover-reduce-opacity;
}

.facebookButton {
  @include provider-login-button;

  background: colors.$color-blue;

  svg {
    width: 20px;
    height: 20px;
  }
}

.appleButton {
  @include provider-login-button;

  color: colors.$color-black;
  background: colors.$color-white;

  --svg-color: #{colors.$color-black};

  svg {
    bottom: 1px;
    width: 20px;
    height: 20px;
    transform: unset;
  }
}

.buttonIconWrapper {
  position: absolute;
  left: 16px;
  display: flex;
  align-items: center;
  height: 100%;
}

.buttonText {
  position: relative;
  top: 1px;
}

.skipLoginForNowText {
  margin-top: 32px;
  text-decoration: underline;

  @include common.hover-reduce-opacity;

  cursor: pointer;
}
