@use "@/sass/variables/colors.module.scss";
@use "@/sass/variables/layout-dimensions.module";
@use "@/sass/abstracts/responsive-vw";
@use "@/sass/abstracts/fonts";

@mixin larger-widths-for-centering-content {
  @media only screen and (min-width: 450px) {
    @content;
  }
}

@mixin position-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @include larger-widths-for-centering-content {
    align-items: center;
  }
}

.loginContainer {
  @include responsive-vw.only-display-for-mobile-widths;
  @include position-content;

  margin-top: 64px;

  @include responsive-vw.support-vw-small-mobiles {
    margin-top: 28px;
  }
}

.topSection {
  @include position-content;

  max-width: 400px;
}

.logo {
  width: 32px;
  height: 30px;
  margin-bottom: 64px;

  @include responsive-vw.support-vw-small-mobiles {
    margin-bottom: 40px;
  }
}

.bodyTextWrapper {
  @include position-content;

  margin-bottom: 64px;

  @include responsive-vw.support-vw-small-mobiles {
    margin-bottom: 40px;
  }
}

.bodyText {
  text-align: left;

  &:not(:last-child) {
    margin-bottom: 32px;
  }

  @include larger-widths-for-centering-content {
    text-align: center;
  }

  @include responsive-vw.support-vw-small-mobiles {
    @include fonts.font-size(90);
  }
}

.bottomSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  width: 100%;
  max-width: 360px;
}

.privacyPolicy {
  @include fonts.font-size(60);

  margin-top: 28px;
  color: colors.$color-grey-x-light;
  text-align: center;

  @include responsive-vw.support-vw-small-mobiles {
    @include fonts.font-size(50);
  }

  a {
    color: white;
    text-decoration: underline;
  }
}
