@use "@/sass/variables/colors.module.scss";
@use "@/sass/variables/layout-dimensions.module";
@use "@/sass/abstracts/responsive-vw";
@use "@/sass/abstracts/fonts";
@use "@/sass/abstracts/common";

@mixin support-small-heights {
  @media only screen and (max-height: 850px) {
    @content;
  }
}

@mixin position-content {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include responsive-vw.support-vw-small-mobiles {
    align-items: flex-start;
  }
}

.loginContainer {
  @include responsive-vw.only-display-for-non-mobile-widths;
  @include position-content;

  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
}

.topSection {
  @include position-content;

  max-width: 540px;
}

.logo {
  width: 32px;
  height: 30px;
  margin-bottom: 64px;
}

.bodyTextWrapper {
  @include position-content;

  margin-bottom: 64px;
}

.bodyText {
  text-align: center;

  &:not(:last-child) {
    margin-bottom: 32px;
  }

  @include responsive-vw.support-vw-small-mobiles {
    text-align: left;
  }
}

.bottomSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  width: 100%;
  max-width: 360px;
}

.privacyPolicyText {
  @include fonts.font-size(60);

  margin-top: 64px;
  color: colors.$color-grey-x-light;
  text-align: center;

  @include responsive-vw.support-vw-small-mobiles {
    @include fonts.font-size(50);
  }

  a {
    color: white;
    text-decoration: underline;
  }
}

.privacyPolicyLink {
  @include common.hover-reduce-opacity;
}
